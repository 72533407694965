import React from 'react'
import linkedin from './assets/linkedin.png'
import instagram from './assets/instagram.png'
import X from './assets/x.png'

import { useNavigate } from 'react-router-dom'
const Footer = () => {
  const navigate = useNavigate()
  return (
    // <div className=' w-full flex flex-col  px-[2rem] sm:px-[4.375rem] md:px-[5rem]  pt-[75px] '>
    //   <div className=' sm:self-center flex flex-col sm:flex-row mt-3  lg:hidden gap-[1.125rem] text-[12px] sm:text-[1rem]'>
    //     <h1 onClick={()=>navigate("/about")} className=' cursor-pointer'>About Us</h1>
    //     <h1 onClick={()=>navigate("/contact")} className=' cursor-pointer'>Contact support</h1>
    //     <h1 onClick={()=>navigate("/privacy-policy")} className=' cursor-pointer'>Privacy Policy</h1>
    //     <h1  onClick={()=>navigate("/terms")} className=' cursor-pointer'>Terms of Use</h1>
    //     <h1  onClick={()=>navigate("/faq")} className=' cursor-pointer'>FAQs</h1>
    //   </div>
    //   <div className='sm:items-center mt-3 lg:mt-0 gap-[1.125rem] sm:gap-0 justify-between sm:text-center flex flex-col sm:flex-row'>
    //     <h1 className=' text-[12px] sm:text-[1.125rem]'> <span className='mr-[0.313rem]'>©</span> 2023 Goodifly. Powered by Modenbo Technologies.</h1>
    //     <div className=' hidden lg:flex gap-[1.125rem] text-[12px] sm:text-[1rem]'>
    //       <h1 onClick={()=>navigate("/about")} className=' cursor-pointer'>About Us</h1>
    //       <h1 onClick={()=>navigate("/contact")} className=' cursor-pointer'>Contact support</h1>
    //       <h1 onClick={()=>navigate("/privacy-policy")} className=' cursor-pointer'>Privacy Policy</h1>
    //       <h1 onClick={()=>navigate("/terms")} className=' cursor-pointer'>Terms of Use</h1>
    //       <h1  onClick={()=>navigate("/faq")} className=' cursor-pointer'>FAQs</h1>
    //     </div>
    //     <div className='flex gap-[5px]'>
    //      <a href="https://www.linkedin.com/company/goodifly" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" className=' w-[1.5rem] sm:w-[2.5rem] h-[1.5rem] sm:h-[2.5rem]' /></a>
    //      <a href="https://www.instagram.com/goodifly/?igsh=cnJoemQwdnZudDQ3&utm_source=qr" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" className=' w-[1.5rem] sm:w-[2.5rem] h-[1.5rem] sm:h-[2.5rem]' /></a>
    //       <a href="https://twitter.com/goodiflyapp" target="_blank" rel="noopener noreferrer"><img src={X} alt="" className=' w-[1.5rem] sm:w-[2.5rem] h-[1.5rem] sm:h-[2.5rem]' /></a>
    //     </div>
    //   </div>
    // </div>

    // new
   <div className="mt-auto">
    <div className=' flex flex-col-reverse ml-[32px] lg:ml-0 md:ml-0  lg:pb-[75px] pb-[58px]  lg:flex-row  md:flex-row lg:px-[0] md:pb-[74px] lg:text-center font-normal text-[14px] md:text-[12px]  pt-[75px] md:leading-[14.56px] leading-[16.98px] md:items-center  lg:items-center lg:justify-around md:justify-around '>
        <div>
          <h1 className='hidden lg:text-[14px] md:text-[12px] leading-[16.98px] lg:leading-[16.98px] md:leading-[14.56px]  lg:flex md:flex gap-[5px]   font-normal md:gap-[3px] items-center'> <span className='font-normal lg:leading-[60.65px] md:leading-[48.52px] lg:text-[50px] md:text-[40px] '>©</span> 2023 Goodifly. Powered by Modenbo Technologies.</h1>
        </div>

      
        
        <div className='flex flex-col gap-[8px]  text-[14px] leading-[16.98px] lg:text-[14px] lg:leading-[16.98px] md:text-[12px] md:leading-[14.56px] font-normal  lg:gap-[30px] lg:mb-0 lg:flex-row md:flex-row'>
         <h1 onClick={()=>navigate("/about")} className=' cursor-pointer'>About Us</h1>
         <h1 onClick={()=>navigate("/contact")} className=' cursor-pointer'>Contact Support</h1>
         <h1 onClick={()=>navigate("/privacy-policy")} className=' cursor-pointer'>Privacy Policy</h1>
         <h1 onClick={()=>navigate("/terms")} className=' cursor-pointer'>Terms of Use</h1>
         <h1  onClick={()=>navigate("/faq")} className=' cursor-pointer'>FAQs</h1>
        </div>



      <div className='flex gap-[10px] mb-[40px] md:mb-0 lg:mb-0'>
          <a href="https://www.linkedin.com/company/goodifly" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="" className='  w-[40px]  h-[40px] lg:w-[30px] lg:h-[30px] md:w-[32px] md:h-[32px] ' /></a>
          <a href="https://www.instagram.com/goodifly/?igsh=cnJoemQwdnZudDQ3&utm_source=qr" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="" className=' w-[40px]  h-[40px] lg:w-[30px] lg:h-[30px] md:w-[32px] md:h-[32px]  ' /></a>
          <a href="https://twitter.com/goodiflyapp" target="_blank" rel="noopener noreferrer"><img src={X} alt="" className=' w-[40px]  h-[40px] lg:w-[30px] lg:h-[30px] md:w-[32px] md:h-[32px] ' /></a>
        </div>
    
    </div>  
    <div >
      <h1 className=' flex justify-center items-center gap-[1px]  pb-[30px] text-[14px] leading-[16.98px] font-normal lg:hidden md:hidden '> <span className='font-normal text-[25px]'>©</span> 2023 Goodifly. Powered by Modenbo Technologies.</h1>
    </div>
    </div>
        
  )
}

export default Footer
